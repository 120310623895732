<!--注册申请列表-->
<template>
  <div style="min-height: 100vh">
    <van-cell-group title="申请记录(单元格左滑撤销申请)"/>
    <van-empty v-if="empList.length===0" description="暂无注册数据"/>
    <div v-if="empList.length>0">
      <van-swipe-cell v-for="(item,index) in empList" :key="index">
        <van-cell :title="item.baseEmployeeTempEntity.name" :label="item.baseEmployeeTempEntity.tel"
                  :value="item.baseEmployeeTempEntity.cooDate"
                  border
                  center
                  is-link
                  @click="ToDetails(item.baseEmployeeTempEntity)">
          <div>
            {{ item.baseEmployeeTempEntity.cooDate }}
            <van-tag
                :color="formatterStatus(item.baseEmployeeTempEntity.status).color">
              {{ formatterStatus(item.baseEmployeeTempEntity.status).text }}
            </van-tag>
          </div>
        </van-cell>
        <template #right>
          <van-button square type="danger" text="撤销" style="width: 100%;height: 100%" @click="deleEmp(item)"/>
        </template>
      </van-swipe-cell>
    </div>
  </div>
</template>

<script>
import {deleteEmp, getEmpList} from "@/api/register/register";
import {mapGetters} from "vuex";

export default {
  name: "RegisterList",
  //组件引入
  components: {},
  //父子组件传值
  props: {},
  //计算属性，可以缓存，避免每次计算都要重新计算
  computed: {
    ...mapGetters({
      centerData: 'getCenterData',
      userData: 'getUserData'
    }),
    /**
     * 格式化状态(文字、颜色)
     */
    formatterStatus() {
      return type => {
        // 4，注册；5，修改，7，拒绝（撤销重新申请）；6、同意（无法撤销）
        let data = {
          4: {
            text: '注册',
            color: '#1989fa'
          },
          5: {
            text: '修改',
            color: '#ff976a'
          },
          6: {
            text: '同意',
            color: '#07c160'
          },
          7: {
            text: '拒绝',
            color: '#ee0a24'
          }
        }
        return data[parseInt(type)] || {
          text: '未知',
          color: '#fafafa'
        }
      }
    }
  },
  //filters 过滤器
  filters: {},
  //数据
  data() {
    return {
      empList: []//申请列表
    }
  },
  //在模板渲染成html前调用,即通常初始化某些属性值,然后再渲染成视图。
  created() {
  },
  //在模板渲染成html后调用，通常是初始化页面完成后，再对html的dom节点进行一些需要的操作。
  mounted() {
    this.getList()
  },
  activated() {
  },
  //方法 处理逻辑
  methods: {
    /**
     * 撤销申请
     */
    deleEmp(item) {
      if (item.status == 6) {
        this.$toast.fail('已经同意的不能撤销!')
        return
      }
      const _this = this
      this.$dialog.confirm({
        title: '取消',
        message: '确认取消此条申请吗？',
      })
          .then(() => {
            _this.$toast.loading({duration: 0, message: '正在撤销中...'})
            let data = {
              id: item.baseEmployeeTempEntity.id,//表id
              enterpriseType: _this.centerData.centerType,//企业类型
              centerId: _this.centerData.id//企业id
            }
            deleteEmp(data).then(res => {
              console.log(res, 'res');
              if (res.code === 0) {
                _this.$toast.success('撤销成功！')
                _this.getList()
              } else {
                _this.$toast.fail(res.msg || '撤销失败、请稍后重试!')
              }
            }).catch(err => {
              _this.$toast.fail('撤销失败、请稍后重试!')
              console.log(err, 'err');
            })
          })
          .catch(() => {
            // on cancel
          });
    },
    /**
     * 详情
     */
    ToDetails(item) {
      console.log(item, 'item');
      this.$router.push({path: '/RegisterDetails', query: item})
    },
    /**
     * 获取申请列表数据
     */
    getList() {
      let data = {
        enterpriseType: this.centerData.centerType,
        centerId: this.centerData.id,
        ...this.userData,
      }
      getEmpList(data).then(res => {
        if (res.code === 0 && res.data) {
          this.empList = res.data.date
          this.$store.commit('SET_PATH', res.data.path)
          this.$store.commit('SET_PATH2', res.data.path2)
        } else {
          this.$toast.fail('获取列表失败,请刷新重试或重新进入!')
        }
      }).catch(err => {
        console.log(err, 'err');
        this.$toast.fail('获取列表失败,请刷新重试或重新进入!')
      })
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .van-cell-group__title {
  background-color: #f6f6f6 !important;
}
</style>
